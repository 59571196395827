import React, { useState } from "react";
import "./cta.css";
import { TextField } from "@mui/material";
import axios from "axios";
import { SlMagnifier } from "react-icons/sl";

const CTA = () => {
  let [email, setEmail] = useState("");

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const response = await axios.post("/api/v1/enquiry/subscribe/", {
        email,
      });
      alert(response.data);
      setEmail("");
    } catch (error) {
      // send the request and the server respond with a status code
      if (error.response) {
        alert(error.response.data);
      } else if (error.request) {
        // This request was made but response was request
        console.log(error.request);
      } else {
        // Something happen in the request
        console.log("Error", error.message);
      }
    }
  };

  return (
    <div className="section__padding">
      <div className=" cta">
        <div className="cta__content">
          <h3>
            {" "}
            <SlMagnifier color="fff" /> Get Diagnosis in 1-2 weeks !
          </h3>
        </div>
      </div>
    </div>
  );
};

export default CTA;
