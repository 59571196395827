import React from "react";

const zahra_image =
  "https://cdn-blob-momentous-health.azureedge.net/employee-image/morgan.JPG";

const zahra_sattaur = {
  slug: "morgan-glusman",
  profile: {
    name: "Morgan Glusman, PhD",
    surname: "PhD",
    image: zahra_image,
    position: "Neuropsychologist",
    motto:
      "Illuminating the secrets of the brain and helping individuals reach their full cognitive potential is not just a profession, it's our driving force.",
  },
  work_experice: {
    "Current Position": [
      {
        company: "Momentous Health, Inc.",
        position: "Neuropsychologist",
      },
    ],
    "Past Position": [
      {
        company: "Naval Hospital Beaufort",
        position: "Clinical Neuropsychologist",
      },
    ],
  },
  education_and_training: [
    {
      position: "Post-doctoral Fellowship in Clinical Neuropsychology",
      location: "University of Oklahoma Health Sciences Center",
    },
    {
      position: "Pre-doctoral Internship in Neuropsychology",
      location: "University of Oklahoma Health Sciences Center",
    },
    {
      position: "PhD, Clinical Psychology, Specialty in Neuropsychology",
      location: "University of Missouri – Kansas City",
    },
    {
      position: "BA, Psychology",
      location: "University of South Alabama ",
    },
  ],
};

export default zahra_sattaur;
