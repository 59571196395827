import {
  FaHospitalUser,
  FaBook,
  FaBriefcase,
  FaHandsHelping,
} from "react-icons/fa";

import React from "react";

import { AiOutlineMail } from "react-icons/ai";
import { FcCellPhone } from "react-icons/fc";

const sublinks = [
  {
    page: "About",
    url: "/about",
  },
  {
    page: "Specialty",
    url: "/specialty",
  },
  {
    page: "Team",
    url: "/team",
  },
  {
    page: "Pricing",
    url: "/pricing",
  },
  {
    page: "FAQs",
    url: "/FAQs",
  },
];

export default sublinks;
