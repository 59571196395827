import React from "react";
import "./sidebar.css";
import { Link } from "react-router-dom";

const Sidebar = ({ data }) => {
  return (
    <div className="sidebar-container">
      <div className="sidebar-links">
        <ul>
          {data.map((sublink, index) => {
            const { page, url } = sublink;
            return (
              <li className="sidebar-link-item" key={index}>
                <Link to={url} style={{ textDecoration: "none" }}>
                  <p>{page}</p>
                </Link>
              </li>
            );
          })}
        </ul>
      </div>

      <div className="submenu-signin-container">
        <button>
          <a href="https://forms.office.com/r/T1W9Nktpyv" target="_blank">
            <p>Refer</p>
          </a>
        </button>
      </div>
    </div>
  );
};

export default Sidebar;
