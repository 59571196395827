import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import React from "react";
import { FAQs } from "../components/index";
import { QuestionaireForms } from "../components/index";
import "./FAQsPage.css";
import { createTheme, ThemeProvider, useTheme } from "@mui/material/styles";
import { useMediaQuery } from "@mui/material";
import { Title, Navbar } from "../components";

//TODO: add a question submit component.

const theme = createTheme({
  typography: {
    fontFamily: ["Manrope", "sans-serif"].join(","),
  },
});

const FAQsPage = () => {
  const theme = useTheme();
  const large = useMediaQuery(theme.breakpoints.up("lg"));
  const medium = useMediaQuery(theme.breakpoints.up("md"));
  const small = useMediaQuery(theme.breakpoints.up("sm"));

  return (
    <div>
      <Title title={"Specialties"} />
      <Navbar />
      <Container
        disableGutters
        sx={{
          pt: 8,
          pb: 6,
          px: 4,
          py: 4,
        }}
        margin="auto"
        component="main"
        className="FAQsPage"
        maxWidth="xl"
      >
        <Typography
          style={{ color: "#ffffff" }}
          fontWeight={500}
          pt={{
            md: 6,
            sm: 3,
          }}
          align="center"
          justifyContent="center"
          variant={large ? "h2" : medium ? "h2" : small ? "h3" : "h4"}
          gutterBottom
          sx={{
            backgroundImage: `linear-gradient(89.97deg, #ae67fa 1.84%, #f49867 102.67%)`,
            WebkitBackgroundClip: "text",
            WebkitTextFillColor: "transparent",
          }}
        >
          Frequently Ask Questions
        </Typography>
        <FAQs />
        <QuestionaireForms />
      </Container>
    </div>
  );
};

export default FAQsPage;
