import * as React from "react";
const CoachingSVG = (props) => (
  <svg
    width={64}
    height={64}
    viewBox="0 0 64 64"
    fill="none"
    xmlns="https://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M21.3333 28.6665C19.3554 28.6665 17.422 28.08 15.7776 26.9812C14.1331 25.8824 12.8513 24.3206 12.0945 22.4933C11.3376 20.6661 11.1396 18.6554 11.5254 16.7156C11.9113 14.7758 12.8637 12.994 14.2622 11.5954C15.6607 10.1969 17.4425 9.24451 19.3824 8.85866C21.3222 8.4728 23.3328 8.67084 25.1601 9.42771C26.9874 10.1846 28.5491 11.4663 29.648 13.1108C30.7468 14.7553 31.3333 16.6887 31.3333 18.6665C31.3333 21.3187 30.2797 23.8622 28.4043 25.7376C26.529 27.6129 23.9854 28.6665 21.3333 28.6665ZM21.3333 12.6665C20.1466 12.6665 18.9865 13.0184 17.9998 13.6777C17.0131 14.337 16.2441 15.2741 15.79 16.3704C15.3359 17.4668 15.217 18.6732 15.4485 19.8371C15.6801 21.0009 16.2515 22.07 17.0906 22.9091C17.9297 23.7483 18.9988 24.3197 20.1627 24.5512C21.3266 24.7827 22.533 24.6639 23.6294 24.2098C24.7257 23.7557 25.6628 22.9866 26.3221 21.9999C26.9814 21.0132 27.3333 19.8532 27.3333 18.6665C27.3333 17.0752 26.7011 15.5491 25.5759 14.4239C24.4507 13.2986 22.9246 12.6665 21.3333 12.6665V12.6665Z"
      fill="#008599"
    />
    <path
      d="M42.6666 28.6667C41.2162 28.6667 39.7984 28.2366 38.5924 27.4308C37.3864 26.625 36.4465 25.4797 35.8915 24.1397C35.3364 22.7997 35.1912 21.3252 35.4742 19.9027C35.7571 18.4801 36.4556 17.1735 37.4811 16.1479C38.5067 15.1223 39.8134 14.4239 41.2359 14.1409C42.6585 13.858 44.1329 14.0032 45.4729 14.5582C46.8129 15.1133 47.9582 16.0532 48.764 17.2592C49.5698 18.4651 49.9999 19.8829 49.9999 21.3333C49.9999 23.2783 49.2273 25.1435 47.852 26.5188C46.4768 27.8941 44.6115 28.6667 42.6666 28.6667ZM42.6666 18C42.0073 18 41.3629 18.1955 40.8147 18.5618C40.2665 18.928 39.8393 19.4486 39.587 20.0577C39.3347 20.6668 39.2687 21.337 39.3973 21.9836C39.5259 22.6302 39.8434 23.2242 40.3096 23.6904C40.7757 24.1565 41.3697 24.474 42.0163 24.6026C42.6629 24.7312 43.3331 24.6652 43.9422 24.4129C44.5513 24.1606 45.0719 23.7334 45.4382 23.1852C45.8044 22.6371 45.9999 21.9926 45.9999 21.3333C45.9999 20.4493 45.6487 19.6014 45.0236 18.9763C44.3985 18.3512 43.5506 18 42.6666 18Z"
      fill="#89BEC6"
    />
    <path
      d="M34.6667 55.3332C34.1384 55.3263 33.6337 55.1133 33.2601 54.7397C32.8865 54.3662 32.6736 53.8615 32.6667 53.3332V47.9998C32.6667 44.994 31.4726 42.1114 29.3472 39.986C27.2218 37.8605 24.3391 36.6665 21.3333 36.6665C18.3275 36.6665 15.4449 37.8605 13.3195 39.986C11.194 42.1114 10 44.994 10 47.9998V53.3332C10 53.8636 9.78929 54.3723 9.41421 54.7474C9.03914 55.1225 8.53043 55.3332 8 55.3332C7.46957 55.3332 6.96086 55.1225 6.58579 54.7474C6.21071 54.3723 6 53.8636 6 53.3332V47.9998C6 43.9332 7.61547 40.0331 10.491 37.1575C13.3666 34.282 17.2667 32.6665 21.3333 32.6665C25.4 32.6665 29.3001 34.282 32.1756 37.1575C35.0512 40.0331 36.6667 43.9332 36.6667 47.9998V53.3332C36.6598 53.8615 36.4468 54.3662 36.0732 54.7397C35.6997 55.1133 35.195 55.3263 34.6667 55.3332V55.3332Z"
      fill="#008599"
    />
    <path
      d="M56 55.3335C55.4717 55.3266 54.967 55.1137 54.5934 54.7401C54.2198 54.3665 54.0069 53.8618 54 53.3335V48.0002C53.9929 44.9966 52.7966 42.118 50.6727 39.9941C48.5488 37.8702 45.6702 36.6739 42.6666 36.6669C41.1079 36.6673 39.5655 36.9849 38.1333 37.6002C37.892 37.7111 37.6311 37.7728 37.3657 37.7818C37.1003 37.7908 36.8358 37.7469 36.5875 37.6526C36.3393 37.5582 36.1123 37.4154 35.9199 37.2324C35.7274 37.0495 35.5734 36.83 35.4666 36.5869C35.2517 36.0952 35.2404 35.5384 35.4354 35.0385C35.6303 34.5386 36.0156 34.1365 36.5066 33.9202C38.4395 33.0707 40.5286 32.6347 42.64 32.6402C46.7137 32.6402 50.6206 34.2585 53.5011 37.139C56.3817 40.0196 58 43.9265 58 48.0002V53.3335C57.9931 53.8618 57.7801 54.3665 57.4065 54.7401C57.0329 55.1137 56.5282 55.3266 56 55.3335V55.3335Z"
      fill="#89BEC6"
    />
    <path
      d="M42.6666 26.6667C45.6121 26.6667 47.9999 24.2789 47.9999 21.3333C47.9999 18.3878 45.6121 16 42.6666 16C39.7211 16 37.3333 18.3878 37.3333 21.3333C37.3333 24.2789 39.7211 26.6667 42.6666 26.6667Z"
      fill="#89BEC6"
    />
  </svg>
);
export default CoachingSVG;
