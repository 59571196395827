import React from "react";
import "./feature.css";

const Feature = ({ title, text, icon = null }) => {
  return (
    <div className="feature">
      <div className="feature-title">
        {/*TODO: change this to image or svg*/}
        <topbar />
        <h1>{title}</h1>
      </div>
      <div className="feature-text">
        <p>{text}</p>
      </div>
    </div>
  );
};

export default Feature;
