import React from "react";
import SVGMap from "./map.jsx";
import "./locations.css";

const Locations = () => {
  return (
    <div className="section__padding locations" id="locations">
      <div className="map">
        <SVGMap />
      </div>

      <div className="description">
        <h1 className="gradient__text">
          Discover exceptional cognitive care at Momentous Health.
        </h1>
        <h2>
          We are growing fast. <span style={{ marginRight: "0.4em" }}></span>
          Join us!
        </h2>
      </div>
    </div>
  );
};

export default Locations;
