import React, { useState } from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import { AiOutlineStar } from "react-icons/ai";
import { Button, CardMedia, useMediaQuery } from "@mui/material";
import CardActions from "@mui/material/CardActions";
import { useTheme } from "@mui/material/styles";
import { Title, Navbar, CTA } from "../components";
import axios from "axios";

const Pricing = () => {
  const theme = useTheme();
  const large = useMediaQuery(theme.breakpoints.up("lg"));
  const medium = useMediaQuery(theme.breakpoints.up("md"));
  const small = useMediaQuery(theme.breakpoints.up("sm"));

  const [serviceName, setServiceName] = useState("");

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const response = await axios.post("api/v1/sales/purchase-start/", {
        serviceName,
      });
      setServiceName("");
    } catch (error) {
      // send the request and the server respond with a status code
      if (error.response) {
        alert(error.response.data);
      } else if (error.request) {
        console.log(error.request);
      } else {
        console.log("Error", error.message);
      }
    }
  };

  return (
    <React.Fragment>
      <Title title={"Pricing"} />
      <Navbar />
      <Container
        disableGutters
        sx={{
          pt: 8,
          pb: 6,
          px: 4,
          py: 4,
        }}
        margin="auto"
        component="main"
      >
        <Typography
          style={{ color: "#ffffff" }}
          fontWeight={500}
          pt={6}
          align="center"
          variant={large ? "h2" : medium ? "h3" : small ? "h4" : "h4"}
          gutterBottom
          sx={{
            backgroundImage: `linear-gradient(89.97deg, #ae67fa 1.84%, #f49867 102.67%)`,
            WebkitBackgroundClip: "text",
            WebkitTextFillColor: "transparent",
          }}
        >
          Pricing
        </Typography>
      </Container>

      <Container maxwidth="md" component="main">
        <Typography
          variant="h4"
          textAlign="center"
          sx={{
            color: "#ff8a71",
            pt: 6,
            pb: 10,
          }}
        >
          Cost is typically fully covered by insurance.
        </Typography>

        <Grid container spacing={5} alignItems="flex-end">
          <Grid item key="medicare" xs={12} sm={6} md={6} lg={3}>
            <Card style={{ background: "transparent", boxShadow: "none" }}>
              <CardMedia
                component="img"
                alt="medicare"
                height="130px"
                width="100%"
                image={require("../assets/Medicare.png")}
                sx={{
                  objectFit: "contain",
                  transition: "filter 0.3s ease",
                  filter: "invert(100%)", // This will turn the image white
                }}
              />
            </Card>
          </Grid>
          <Grid item key="bluecross" xs={12} sm={6} md={6} lg={3}>
            <Card style={{ background: "transparent", boxShadow: "none" }}>
              <CardMedia
                component="img"
                alt="bluecross"
                height="100px"
                width="50%"
                image={require("../assets/bluecross.svg").default}
                sx={{
                  objectFit: "contain",
                  transition: "filter 0.3s ease",
                  filter: "invert(30%)", // This will turn the image white
                }}
              />
            </Card>
          </Grid>
          <Grid item key="cigna" xs={12} sm={6} md={6} lg={3}>
            <Card style={{ background: "transparent", boxShadow: "none" }}>
              <CardMedia
                component="img"
                alt="cigna"
                height="100px"
                width="50%"
                image={require("../assets/cigna.png")}
                sx={{
                  objectFit: "contain",
                  transition: "filter 0.3s ease",
                  filter: "invert(30%)", // This will turn the image white
                }}
              />
            </Card>
          </Grid>
          <Grid item key="humana" xs={12} sm={6} md={6} lg={3}>
            <Card style={{ background: "transparent", boxShadow: "none" }}>
              <CardMedia
                component="img"
                alt="humana"
                height="80px"
                width="50%"
                image={require("../assets/humana.png")}
                sx={{
                  objectFit: "contain",
                  transition: "filter 0.3s ease",
                  filter: "invert(30%)", // This will turn the image white
                }}
              />
            </Card>
          </Grid>
          <Grid item key="united" xs={12} sm={6} md={6} lg={3}>
            <Card style={{ background: "transparent", boxShadow: "none" }}>
              <CardMedia
                component="img"
                alt="united"
                height="100px"
                width="50%"
                image={require("../assets/united.png")}
                sx={{
                  objectFit: "contain",
                  transition: "filter 0.3s ease",
                  filter: "invert(50%)", // This will turn the image white
                }}
              />
            </Card>
          </Grid>

          <Grid item key="optimum" xs={12} sm={6} md={6} lg={3}>
            <Card style={{ background: "transparent", boxShadow: "none" }}>
              <CardMedia
                component="img"
                alt="Optimum"
                height="100px"
                width="50%"
                image={require("../assets/optimum.png")}
                sx={{
                  objectFit: "contain",
                  transition: "filter 0.3s ease",
                }}
              />
            </Card>
          </Grid>

          <Grid item key="atheme" xs={12} sm={6} md={6} lg={3}>
            <Card style={{ background: "transparent", boxShadow: "none" }}>
              <CardMedia
                component="img"
                alt="Anthem"
                height="100px"
                width="50%"
                image={require("../assets/anthem.png")}
                sx={{
                  objectFit: "contain",
                  transition: "filter 0.3s ease",
                }}
              />
            </Card>
          </Grid>

          <Grid item key="aetna" xs={12} sm={6} md={6} lg={3}>
            <Card style={{ background: "transparent", boxShadow: "none" }}>
              <CardMedia
                component="img"
                alt="Aetna"
                height="100px"
                width="50%"
                image={require("../assets/aetna.png")}
                sx={{
                  objectFit: "contain",
                  transition: "filter 0.3s ease",
                }}
              />
            </Card>
          </Grid>
        </Grid>
      </Container>
      <Container maxwidth="md" component="main" sx={{ pt: 6, pb: 4 }}>
        <Typography
          sx={{
            color: (theme) => theme.palette.grey[400],
            pt: 1,
            pb: 2,
          }}
        ></Typography>
        <CTA />
      </Container>
    </React.Fragment>
  );
};

const tiers = [
  {
    title: "Cognitive  Treatment",
    price: "199",
    time: "/50 mins",
    description: [
      "50 mins session",
      "Personalized treatment",
      "1 on 1 with your doctors",
      "Evidence-based treatment",
    ],
    action: "Call 800-679-1730",
    buttonVariant: "contained",
  },
  {
    title: "Cognitive Evaluation",
    price: "3499",
    time: "",
    description: [
      "Neuropsychology diagonosis",
      "Multiple sessions",
      "3-month follow up",
      "Alzhamers', Brain injury ...",
    ],
    action: "Call 800-679-1730",
    buttonVariant: "outlined",
  },
];

export default Pricing;
