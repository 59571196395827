import React from "react";
import Container from "@mui/material/Container";
import IconButton from "@mui/material/IconButton";
import Love from "../../assets/Love";

const LoveSVG = () => {
  return (
    <Container
      maxWidth="md"
      align="center"
      justifyContent="center"
      sx={{ paddingTop: "4rem" }}
    >
      <IconButton>
        <Love />
      </IconButton>
    </Container>
  );
};

export default LoveSVG;
