import React from "react";
import Container from "@mui/material/Container";
import { Typography } from "@mui/material";
import { BsAward } from "react-icons/bs";
import Box from "@mui/material/Box";
import { FaMicroscope } from "react-icons/fa";

import {
  ServicesPage_Header,
  LoveSVG,
  ScrolDownSVG,
} from "./ServicesPage/index";

import { Title, Navbar } from "../components";
import CoachingSVG from "../assets/Coaching.jsx";

const ServicesPage = () => {
  return (
    <div>
      <Navbar />

      <Container
        disableGutters
        sx={{
          pt: 8,
          pb: 6,
          px: 4,
          py: 4,
        }}
        margin="auto"
        component="main"
      >
        <Title title={"Specialties"} />

        <ServicesPage_Header />
        <Box
          maxWidth="lg"
          margin="auto"
          sx={{
            "@media (max-width: 600px)": {
              maxWidth: "90vw", // Adjust the maxWidth for small screens
            },
          }}
        >
          <Typography
            variant="h6"
            sx={{
              color: "#708090",
              pt: 6,
              pl: 6,
              pb: 4,
            }}
          >
            <BsAward /> - Our specialty lies in evaluating and treating
            cognitive symptoms caused by a variety of conditions:
          </Typography>
          <Box maxWidth="md" margin="auto">
            {conditions.map((condition) => (
              <Typography
                variant="h6"
                sx={{
                  color: "#8c8c8c",
                  pt: 1,
                  "&:hover": {
                    color: "#ff8a71",
                  },
                }}
              >
                • {condition}
              </Typography>
            ))}
          </Box>
        </Box>

        <Box
          maxWidth="lg"
          margin="auto"
          sx={{
            "@media (max-width: 600px)": {
              maxWidth: "90vw", // Adjust the maxWidth for small screens
            },
          }}
        >
          <Typography
            variant="h6"
            sx={{
              color: "#708090",
              pt: 6,
              pl: 6,
              pb: 4,
            }}
          >
            <FaMicroscope /> - If you are experiencing the following cognitive
            symptoms, we can help you address them effectively:
          </Typography>
          <Box maxWidth="md" margin="auto">
            {symptoms.map((symptom) => (
              <Typography
                variant="h6"
                sx={{
                  color: "#8c8c8c",
                  pt: 1,
                  "&:hover": {
                    color: "#ff8a71",
                  },
                }}
              >
                • {symptom}
              </Typography>
            ))}
          </Box>
        </Box>
        <LoveSVG />
      </Container>
    </div>
  );
};

const conditions = [
  "Dementia",
  "Alzheimer's disease",
  "Mild cognitive impairment",
  "Cognitive decline due to aging",
  "Brain injury/Concussion",
  "Attention-Deficit/Hyperactivity Disorder (ADHD)",
  "Stroke",
  "Parkinson’s disease",
  "Multiple sclerosis",
  "Epilepsy",
  "Brain tumors",
  "Long Covid",
  "Brain fog",
];

const symptoms = [
  "Memory loss",
  "Poor attention and concentration",
  "Procrastination",
  "Avoidance of mentally demanding tasks",
  "Difficulties with multi-tasking",
  "Deficits with problem-solving and reasoning skills",
  "Poor organization of tasks",
  "Difficulties with decision-making",
  "Challenges with learning and academic skills",
  "Slowed mental thinking",
  "Language and communication difficulties",
  "Visual-spatial problems",
];

export default ServicesPage;
