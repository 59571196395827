import React from "react";
import { Feature } from "../../components";
import "./services.css";

const text1 =
  "We  offer online cognitive evaluations for a variety of neurological, medical, and psychological conditions. Our goal is to help you better understand your cognitive health, enabling you to make informed choices and take proactive steps towards optimal brain function.";
const text2 =
  "We  provide online cognitive rehabilitation to help maximize your cognitive abilities and quality of life, utilizing evidence-based techniques. Our approach involves future follow-up to monitor progress over time and ensure lasting results.";
const text3 =
  "Our research team is currently developing advanced technology to provide real-time monitoring and feedback on cognitive symptoms, optimizing precision and cognitive care outcomes.";

const Services = () => {
  return (
    <div className="services section__padding" id="Services">
      <div className="gradient__text service__title">Our Services</div>
      <div className="service-container">
        <Feature title="Cognitive Evaluation" text={text1} />
        <Feature title="Cognitive Rehabilitation" text={text2} />
        <Feature title="Cognitive Research" text={text3} />
      </div>
    </div>
  );
};

export default Services;
