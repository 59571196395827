const blog01 =
  "https://cdn-blob-momentous-health.azureedge.net/mh-web-image/Introduce_mh.jpg";
const blog02 =
  "https://cdn-blob-momentous-health.azureedge.net/mh-web-image/memory_forgetting.jpg";
const blog03 =
  "https://cdn-blob-momentous-health.azureedge.net/mh-web-image/rehab_image.jpg";
const blog04 =
  "https://cdn-blob-momentous-health.azureedge.net/mh-web-image/stay_focus.jpg";
const blog05 =
  "https://cdn-blob-momentous-health.azureedge.net/mh-web-image/neuro_test.jpg";

export { blog01, blog02, blog03, blog04, blog05 };
