import * as React from "react";
const PlantSVG = (props) => (
  <svg
    width={64}
    height={64}
    viewBox="0 0 64 64"
    fill="none"
    xmlns="https://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M31.9999 30.8799H27.5466C25.8029 30.8851 24.0861 31.3094 22.5407 32.1169C20.9953 32.9244 19.6666 34.0915 18.6666 35.5199C17.6666 34.0915 16.3379 32.9244 14.7925 32.1169C13.2471 31.3094 11.5302 30.8851 9.78659 30.8799H5.33325C4.80497 30.8868 4.30026 31.0997 3.92668 31.4733C3.55309 31.8469 3.34016 32.3516 3.33325 32.8799C3.33324 35.7679 4.47867 38.538 6.51831 40.5826C8.55794 42.6273 11.3252 43.7795 14.2133 43.7865H16.6666V53.3332C16.6666 53.8636 16.8773 54.3724 17.2524 54.7474C17.6274 55.1225 18.1362 55.3332 18.6666 55.3332C19.197 55.3332 19.7057 55.1225 20.0808 54.7474C20.4559 54.3724 20.6666 53.8636 20.6666 53.3332V43.7865H23.1199C26.0079 43.7795 28.7752 42.6273 30.8149 40.5826C32.8545 38.538 33.9999 35.7679 33.9999 32.8799C33.993 32.3516 33.7801 31.8469 33.4065 31.4733C33.0329 31.0997 32.5282 30.8868 31.9999 30.8799V30.8799ZM14.2133 39.7865C12.7348 39.7814 11.297 39.3019 10.1113 38.4187C8.92565 37.5354 8.05471 36.295 7.62658 34.8799H9.78659C11.2651 34.885 12.7029 35.3645 13.8885 36.2477C15.0742 37.131 15.9451 38.3714 16.3733 39.7865H14.2133ZM23.1199 39.7865H20.9599C21.388 38.3714 22.259 37.131 23.4446 36.2477C24.6303 35.3645 26.0681 34.885 27.5466 34.8799H29.7066C29.2785 36.295 28.4075 37.5354 27.2219 38.4187C26.0362 39.3019 24.5984 39.7814 23.1199 39.7865V39.7865Z"
      fill="#9ABFAF"
    />
    <path
      d="M58.6667 14.8799H54.2133C52.4697 14.8851 50.7528 15.3094 49.2074 16.1169C47.662 16.9244 46.3334 18.0915 45.3333 19.5199C44.3333 18.0915 43.0046 16.9244 41.4592 16.1169C39.9138 15.3094 38.197 14.8851 36.4533 14.8799H32C31.4717 14.8868 30.967 15.0997 30.5934 15.4733C30.2198 15.8469 30.0069 16.3516 30 16.8799C30 19.7679 31.1454 22.538 33.1851 24.5826C35.2247 26.6273 37.992 27.7795 40.88 27.7865H43.3333V53.3332C43.3333 53.8636 43.544 54.3724 43.9191 54.7474C44.2942 55.1225 44.8029 55.3332 45.3333 55.3332C45.8638 55.3332 46.3725 55.1225 46.7475 54.7474C47.1226 54.3724 47.3333 53.8636 47.3333 53.3332V27.7865H49.7867C52.6747 27.7795 55.442 26.6273 57.4816 24.5826C59.5212 22.538 60.6667 19.7679 60.6667 16.8799C60.6598 16.3516 60.4468 15.8469 60.0732 15.4733C59.6997 15.0997 59.195 14.8868 58.6667 14.8799V14.8799ZM40.88 23.7865C39.4015 23.7814 37.9637 23.3019 36.7781 22.4187C35.5924 21.5354 34.7215 20.295 34.2933 18.8799H36.4533C37.9318 18.885 39.3696 19.3645 40.5553 20.2477C41.7409 21.131 42.6119 22.3714 43.04 23.7865H40.88ZM49.7867 23.7865H47.6267C48.0548 22.3714 48.9257 21.131 50.1114 20.2477C51.2971 19.3645 52.7349 18.885 54.2133 18.8799H56.3733C55.9452 20.295 55.0743 21.5354 53.8886 22.4187C52.7029 23.3019 51.2651 23.7814 49.7867 23.7865V23.7865Z"
      fill="#36745A"
    />
  </svg>
);
export default PlantSVG;
