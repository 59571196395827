import React from "react";

const bradleyURL =
  "https://cdn-blob-momentous-health.azureedge.net/employee-image/brad.png";

const bradley_reynolds = {
  slug: "bradley-reynolds",
  profile: {
    name: "Bradley Reynolds, PhD",
    surname: "PhD",
    image: bradleyURL,
    position: "Lead Neuropsychologist",
    motto:
      "Illuminating the secrets of the brain and helping individuals reach their full cognitive potential is not just a profession, it's our driving force.",
  },
  work_experice: {
    "Current Position": [
      {
        company: "Momentous Health, Inc.",
        position: "Lead Neuropsychologist",
      },
    ],

    "Past Position": [
      {
        company: "Loma Linda University Medical Center",
        position: "Clinical Neuropsychologist",
      },
    ],
  },

  education_and_training: [
    {
      position: "Post-doctoral Fellowship in Clinical Neuropsychology",
      location: "Cedars-Sinai Medical Center",
    },
    {
      position: "Pre-doctoral Internship in Neuropsychology",
      location: "Patton State Hospital",
    },
    {
      position: "PhD, Clinical Psychology, Specialty in Neuropsychology",
      location: "University of Tulsa",
    },
    {
      position: "BA, Psychology",
      location: "California State University – Long Beach",
    },
  ],
};

export default bradley_reynolds;
