import React from "react";

const jerry_image =
  "https://cdn-blob-momentous-health.azureedge.net/employee-image/JerryChen.png";

const jerry_chen = {
  slug: "jerry-chen",
  profile: {
    name: "Jerry Chen, PhD",
    surname: "PhD",
    image: jerry_image,
    position: "Senior Neuropsychologist",
    licenseLocation: "California",
    licenseNumber: "#33700",
    motto:
      "Illuminating the secrets of the brain and helping individuals reach their full cognitive potential is not just a profession, it's our driving force.",
  },
  work_experice: {
    "Current Position": [
      {
        company: "Momentous Health, Inc.",
        position: "Senior Neuropsychologist",
      },
    ],
  },
  education_and_training: [
    {
      position: "Post-doctoral fellowship in Clinical Neuropsychology",
      location: "UC San Francisco and San Francisco VA Medical Center",
    },
    {
      position: "Pre-doctoral Internship in Neuropsychology",
      location: "Rusk Rehabilitation at NYU Langone",
    },
    {
      position: "PhD, Clinical Psychology, Specialty in Neuropsychology",
      location: "Palo Alto University",
    },
    {
      position: "BA, Neuropsychology",
      location: "UC Santa Cruz",
    },
  ],
};

export default jerry_chen;
