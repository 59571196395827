import React from "react";
import { Container } from "@mui/material";
import { Navbar } from "../components";

import { Title } from "../components";

const ReserveSeats = () => {
  return (
    <div>
      <Navbar />

      <Container
        disableGutters
        sx={{
          pt: 8,
          pb: 6,
          px: 4,
          py: 4,
        }}
        margin="auto"
        component="main"
      >
        <iframe
          title="Forms Submit"
          src="https://forms.office.com/Pages/ResponsePage.aspx?id=retN3T7N-Ua-hkjHKkMjNz5NcZ19xeJCvCbtzkQbuPVUN1BHWVhWSUI1VkFTQVFRUkdETjA2SjhGVC4u&embed=true"
          width="100%"
          height="900px"
          style={{ border: "none", maxWidth: "100%", maxHeight: "100vh" }}
          allowFullScreen
          webkitAllowFullScreen
          mozAllowFullScreen
          msAllowFullScreen
        ></iframe>
      </Container>
    </div>
  );
};

export default ReserveSeats;
