import React from "react";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import CardMedia from "@mui/material/CardMedia";
import Paper from "@mui/material/Paper";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import { MdSchool } from "react-icons/md";
import { FaBuilding, FaMicroscope } from "react-icons/fa";
import { IoMdAirplane } from "react-icons/io";
import { MdWork } from "react-icons/md";
import { AiOutlineRight } from "react-icons/ai";
import { useParams } from "react-router-dom";
import { LiaCertificateSolid } from "react-icons/lia";
import {
  rui_ren,
  lily_lau,
  bradley_reynolds,
  jerry_chen,
  zahra_sattaur,
  morgan_glusman,
  jordan_hoffmeister,
  adolfo_luna,
  sebastian_ren,
  trista_protzeller,
  ishawn,
  pelin_strebler,
} from "./Doctors";
import { BiRadioCircle } from "react-icons/bi";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Navbar } from "../components";

const theme = createTheme({
  typography: {
    fontFamily: [
      "Manrope", // Add other font fallbacks if needed
      "sans-serif",
    ].join(","),
  },
});

// TODO: temperarily disable the Tech employees
const data = [
  // rui_ren,
  lily_lau,
  bradley_reynolds,
  jerry_chen,
  zahra_sattaur,
  morgan_glusman,
  jordan_hoffmeister,
  // adolfo_luna,
  // sebastian_ren,
  trista_protzeller,
  ishawn,
  pelin_strebler,
];

const DoctorPage = () => {
  // const [doctorInfo] = data;

  const { id, doctorName } = useParams();

  // find the render info that has the Name
  const doctorInfo = data.find((user) => user.slug === doctorName);

  // if use the description for doctorInfo
  if (doctorInfo.hasOwnProperty("description")) {
    return (
      <ThemeProvider theme={theme}>
        <Navbar />
        <div align="center">
          <Profile profile={doctorInfo.profile} />
          <Typography
            variant="subtitle1"
            maxWidth="md"
            pt={6}
            pb={10}
            px={4}
            align="center"
            sx={{
              textAlign: "center",
              color: "grey.400",
              lineHeight: 2,
              textAlign: "justify",
              textJustify: "inter-word",
              msHyphens: "auto",
            }}
          >
            {doctorInfo.description}
          </Typography>
        </div>
      </ThemeProvider>
    );
  }

  // if use the CV as doctorInfo
  return (
    <div>
      <Navbar />

      <Profile profile={doctorInfo.profile} />

      <Work work_history={doctorInfo.work_experice} />

      {doctorInfo.certification && (
        <Certification certification={doctorInfo.certification} />
      )}

      <Education education_history={doctorInfo.education_and_training} />

      {doctorInfo.research_focus && (
        <Research research_interests={doctorInfo.research_focus} />
      )}

      <Container align="center" sx={{ pt: 4, pb: 4, mt: 4 }}></Container>
    </div>
  );
};

const Profile = (props) => {
  const { profile } = props;
  return (
    <Container
      maxWidth="lg"
      sx={{
        pt: 13,
        pb: 3,
        bgcolor: "grep.400",
      }}
    >
      <Grid container sx={{ justifyContent: "center" }}>
        <Grid item>
          <CardMedia
            component="img"
            sx={{ width: 250 }}
            image={profile.image}
            alt={profile.name}
          />
        </Grid>
        <Grid item xs={12} md={12} lg={12} textAlign="center">
          <Paper sx={{ bgcolor: "#01050D" }}>
            <Stack justifyContent="space-between">
              <Typography
                style={{
                  backgroundImage: `linear-gradient(89.97deg, #ae67fa 1.84%, #f49867 102.67%)`,
                  WebkitBackgroundClip: "text",
                  WebkitTextFillColor: "transparent",
                }}
                pt={2}
                align="center"
                variant="h4"
                gutterBottom
              >
                {profile.name}
              </Typography>
              <Typography variant="h6" mt={0} sx={{ color: "grey.200" }}>
                {profile.position}
              </Typography>
            </Stack>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
};

const Work = (props) => {
  const { work_history } = props;

  const workHistoryElements = [];
  var idx = 0;
  for (const [key, value] of Object.entries(work_history)) {
    idx++;

    workHistoryElements.push(
      <Container maxWidth="md" align="left" sx={{ mt: 4, pt: 4 }}>
        <Typography variant="h5" align="left" color="#d9d9d9">
          <span>
            {idx === 1 ? <FaBuilding /> : <MdWork />} {key}
          </span>
          <Divider color="#ff8a71" />
        </Typography>
        {value.map((data) => (
          <Typography
            variant="h6"
            sx={{ mt: 0.5, pt: 0.5, px: 2, color: "#a0b0c0" }}
          >
            <span>{data.position}</span>

            <Typography variant="subtitle1" color="grey" sx={{ px: 2 }}>
              <BiRadioCircle />
              <span> {data.company}</span>
            </Typography>
          </Typography>
        ))}
      </Container>
    );
  }

  return workHistoryElements;
};

const Education = (props) => {
  const { education_history } = props;
  return (
    <Container maxWidth="md" align=" left" sx={{ mt: 4, pt: 4 }}>
      <Typography variant="h5" align="left" color="#d9d9d9">
        <span>
          <MdSchool /> Education
        </span>
        <Divider color="#ff8a71" />
      </Typography>
      {education_history.map((data) => (
        <Typography
          variant="h6"
          sx={{ mt: 0.5, pt: 0.5, px: 2, color: "#a0b0c0" }}
        >
          <span>{data.position}</span>

          <Typography variant="subtitle1" color="grey" sx={{ px: 2 }}>
            <span>
              <BiRadioCircle /> {data.location}
            </span>
          </Typography>
        </Typography>
      ))}
    </Container>
  );
};

const Certification = (props) => {
  const { certification } = props;
  return (
    <Container maxWidth="md" align=" left" sx={{ mt: 4, pt: 4 }}>
      <Typography variant="h5" align="left" color="#d9d9d9">
        <span>
          <LiaCertificateSolid /> Certification
        </span>
        <Divider color="#ff8a71" />
      </Typography>
      {certification.map((data) => (
        <Typography
          variant="h6"
          sx={{ mt: 0.5, pt: 0.5, px: 2, color: "#a0b0c0" }}
        >
          <span>{data.position}</span>

          <Typography variant="subtitle1" color="grey" sx={{ px: 2 }}>
            <span>
              <BiRadioCircle /> {data.location}
            </span>
          </Typography>
        </Typography>
      ))}
    </Container>
  );
};

const Research = (props) => {
  const { research_interests } = props;

  return (
    <Container maxWidth="md" align="left" sx={{ mt: 4, pt: 4, px: 2 }}>
      <Typography variant="h5" align="left" color="#bfbfbf">
        <span>
          <FaMicroscope /> Research
        </span>
        <Divider color="#ff8a71" />
      </Typography>
      {research_interests.map((data) => {
        return (
          <Typography
            varint="subtitle1"
            sx={{ mt: 1, pt: 1, color: "#708090" }}
          >
            <AiOutlineRight /> {data}
          </Typography>
        );
      })}
    </Container>
  );
};

export default DoctorPage;
