import React from "react";
import Resource from "../sections/resource/Resource";
import { Navbar } from "../components";
import { Article } from "../components";

const blog01 =
  "https://cdn-blob-momentous-health.azureedge.net/mh-web-image/blog01.png";

const BlogPage = () => {
  return (
    <React.Fragment>
      <Navbar />
      <Resource />
    </React.Fragment>
  );
};

export default BlogPage;
