import React from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import CoachingSVG from "../../assets/Coaching.jsx";
import SelfCareSVG from "../../assets/SelfCare.jsx";
import PlantSVG from "../../assets/Plant.jsx";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import { createTheme, ThemeProvider, useTheme } from "@mui/material/styles";
import { useMediaQuery } from "@mui/material";

const service_data = [
  {
    image: <CoachingSVG />,
    text: "Cognitive Evaluation",
  },
  {
    image: <SelfCareSVG />,
    text: "Cognitive Rehabilitation",
  },
  {
    image: <PlantSVG />,
    text: "Advanced Research",
  },
];

const ServicesPage_Header = () => {
  const theme = useTheme();
  const large = useMediaQuery(theme.breakpoints.up("lg"));
  const medium = useMediaQuery(theme.breakpoints.up("md"));
  const small = useMediaQuery(theme.breakpoints.up("sm"));

  return (
    <Container className="ServicesPage_Header">
      <ThemeProvider theme={theme}>
        <Typography
          style={{ color: "#ffffff" }}
          fontWeight={500}
          pt={{
            md: 6,
            sm: 3,
          }}
          align="center"
          justifyContent="center"
          variant={large ? "h2" : medium ? "h2" : small ? "h3" : "h4"}
          gutterBottom
          sx={{
            backgroundImage: `linear-gradient(89.97deg, #ae67fa 1.84%, #f49867 102.67%)`,
            WebkitBackgroundClip: "text",
            WebkitTextFillColor: "transparent",
          }}
        >
          Areas of Expertise
        </Typography>
      </ThemeProvider>

      {/*The banner*/}

      <Grid alignContent="center" pt={6}>
        <Grid item>
          <Divider
            color="#434343"
            pt={6}
            sx={{
              height: 1.5,
              width: "92x",
            }}
          />
        </Grid>

        <Grid item pt={3} display="flex" justifyContent="space-between">
          {service_data.map((data) => (
            <Card
              sx={{ display: "flex" }}
              style={{ backgroundColor: "#01050D" }}
            >
              <CardMedia>{data.image}</CardMedia>
              <CardContent
                sx={{ display: { xs: "none", sm: "block", md: "block" } }}
              >
                <Typography
                  variant={large ? "h5" : medium ? "h6" : small ? "body2" : "p"}
                  justifyItems="center"
                  align="center"
                  color="#fafafa"
                >
                  {data.text}
                </Typography>
              </CardContent>
            </Card>
          ))}
        </Grid>
      </Grid>
    </Container>
  );
};

export default ServicesPage_Header;
