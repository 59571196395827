import React from "react";
import "./header.css";
import { TextLoop } from "react-text-loop-next";
import { Link } from "react-router-dom";

const brain =
  "https://cdn-blob-momentous-health.azureedge.net/mh-web-image/brain.png";

const Header = () => {
  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  return (
    <div className="header section__padding" id="home">
      <div className="content">
        <h1 className="gradient__text">Cognitive Wellness </h1>
        <div className="loop__text">
          <TextLoop>
            <span>
              {" "}
              <h2>Accurate diagnostics </h2>{" "}
            </span>

            <span>
              <h2>Effective treatments</h2>
            </span>

            <span>
              <h2>Innovative research</h2>
            </span>

            <span>
              <h2> Better brain health</h2>
            </span>
          </TextLoop>
        </div>

        <p>
          Our team of expert neuropsychologists provide online cognitive
          evaluations and rehabilitation for adults and geriatric populations.
          We address cognitive symptoms caused by conditions such as dementia,
          Alzheimer’s disease, brain injury, ADHD, and many more. From initial
          evaluation to personalized treatment and future follow-up, allow us to
          bring exceptional cognitive care to you.
        </p>
        <div className="button">
          <Link to="/about" onClick={scrollToTop}>
            <span class="screen-reader-text">Details</span>
            <button type="button" className="button1">
              Learn More
            </button>
          </Link>

          <a
            href="https://outlook.office365.com/owa/calendar/MomentousMedicalSchedule@momentous-health.com/bookings/s/QF8qyjUHj0GQTk8NHmPvaA2"
            target="_blank"
          >
            <button type="button" className="button2">
              Schedule Now
            </button>
          </a>
        </div>
      </div>
      <div className="img">
        <img
          src={brain}
          alt="Alzheimer's disease and demential-affected brain"
        />
      </div>
    </div>
  );
};

export default Header;
