import React from "react";

const pelin_image =
  "https://cdn-blob-momentous-health.azureedge.net/employee-image/pelin.png";

const pelin_strebler = {
  slug: "pelin-strebler",
  profile: {
    name: "Pelin Strebler, PhD, ABPP-CN",
    surname: "PhD",
    image: pelin_image,
    position: "Board Certified Neuropsychologist",
    motto:
      "Illuminating the secrets of the brain and helping individuals reach their full cognitive potential is not just a profession, it's our driving force.",
  },
  work_experice: {
    "Current Position": [
      {
        company: "Momentous Health, Inc.",
        position: "Senior Neuropsychologist",
      },
    ],
  },
  certification: [
    {
      position: "Board Certified in Clinical Neuropsychology",
      location: "American Board of Professional Psychology (ABPP) ",
    },
  ],
  education_and_training: [
    {
      position: "Post-doctoral fellowship in Clinical Neuropsychology",
      location: "Henry Ford Health System Neuropsychology Division",
    },
    {
      position: "Pre-doctoral Internship in Neuropsychology  ",
      location:
        "University of Alabama at Birmingham (UAB) and the Birmingham VA Medical Center (BVAMC) Internship Consortium",
    },
    {
      position: "PhD, Clinical Psychology, Specialty in Neuropsychology",
      location: "Eastern Michigan University",
    },
    {
      position: "BA, Psychology",
      location: "Istanbul Technical University",
    },
  ],
};

export default pelin_strebler;
