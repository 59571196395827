import React, { useState, useEffect, useCallback } from "react";
import { CssBaseline } from "@mui/material";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Header from "./Blog/Header";
import MainFeaturedPost from "./Blog/MainFeaturedPost";
import FeaturedPost from "./Blog/FeaturedPost";
import Main from "./Blog/Main";
import Sidebar from "./Blog/Sidebar";
import Footer from "./Blog/Footer";
import {
  BsFacebook,
  BsLinkedin,
  BsTwitter,
  BsYoutube,
  BsInstagram,
} from "react-icons/bs";
import { useNavigate, Link, useParams } from "react-router-dom";

const sections = [
  { title: "Technology", url: "#" },
  { title: "Health", url: "#" },
  { title: "Neuropsychology", url: "#" },
  { title: "NeuroRehabilitation", url: "#" },
  { title: "Research", url: "#" },
];

const mainFeaturedPost = {
  title: "",
  description: "",
  image:
    "https://cdn-blob-momentous-health.azureedge.net/mh-web-image/blog-background-image.jpg",
  imageText: "",
  linkText: "",
};

const sidebar = {
  title: "About",
  description: "Momentous Health provides you the best care",
  archives: [
    { title: "Jan 2023", url: "#" },
    { title: "Feb 2023", url: "#" },
  ],
  social: [
    {
      name: "Twitter",
      icon: BsTwitter,
      url: "https://twitter.com/HealthMomentous",
    },
    { name: "Facebook", icon: BsFacebook, url: "" },
    {
      name: "Youtube",
      icon: BsYoutube,
      url: "https://www.youtube.com/@brain_cafe",
    },
    {
      name: "LinkedIn",
      icon: BsLinkedin,
      url: "https://www.linkedin.com/company/momentous-health-inc/?viewAsMember=true",
    },
    {
      name: "Instgram",
      icon: BsInstagram,
      url: "https://www.instagram.com/brain_cafe_/",
    },
  ],
};

const theme = createTheme();

const Blog = () => {
  const { id, blogName } = useParams();

  return (
    <div style={{ background: "#ffffff" }}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Container maxWidth="lg">
          <Header title="Blog" sections={sections} />
          <main>
            {<MainFeaturedPost post={mainFeaturedPost} />}
            <Grid container spacing={5} sx={{ mt: 3, p: 2 }}>
              <Main title="" blog_name={blogName} />
              <Sidebar
                title={sidebar.title}
                description={sidebar.description}
                archives={sidebar.archives}
                social={sidebar.social}
              />
            </Grid>
          </main>
          <Footer title="Momentous Health" />
        </Container>
      </ThemeProvider>
    </div>
  );
};

export default Blog;
