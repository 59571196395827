import React from "react";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import { BsAward } from "react-icons/bs";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
import { RiDoubleQuotesL, RiDoubleQuotesR } from "react-icons/ri";
import { useMediaQuery } from "@mui/material";
import { Title, Navbar } from "../components";

const about_png =
  "https://cdn-blob-momentous-health.azureedge.net/mh-web-image/about_us.png";

const AboutUs = () => {
  const theme = useTheme();
  const large = useMediaQuery(theme.breakpoints.up("lg"));
  const medium = useMediaQuery(theme.breakpoints.up("md"));
  const small = useMediaQuery(theme.breakpoints.up("sm"));

  return (
    <div>
      <Navbar />
      <Container
        disableGutters
        sx={{
          pt: 8,
          pb: 6,
          px: 4,
          py: 4,
        }}
        margin="auto"
        component="main"
      >
        <Title title={"About Momentous"} />
        <Typography
          style={{ color: "#ffffff" }}
          fontWeight={500}
          pt={{
            md: 6,
            sm: 3,
          }}
          align="center"
          justifyContent="center"
          variant={large ? "h2" : medium ? "h2" : small ? "h3" : "h4"}
          gutterBottom
          sx={{
            backgroundImage: `linear-gradient(89.97deg, #ae67fa 1.84%, #f49867 102.67%)`,
            WebkitBackgroundClip: "text",
            WebkitTextFillColor: "transparent",
          }}
        >
          Momentous Health
        </Typography>

        <CardMedia
          component="img"
          image={about_png}
          alt="Neuropsychological test and treatment"
          sx={{
            maxWidth: 780,
            margin: "auto",
            pt: 6,
            pb: 6,
          }}
        ></CardMedia>

        <Typography
          maxWidth="md"
          text-align="justify"
          style={{
            color: "#8c8c8c",
            pt: 6,
            pt: 6,
            fontWeight: 500,
            fontSize: "18px",
          }}
          margin="auto"
          variant="body1"
        >
          We believe that everyone should have access to expert cognitive care.
          That's why our team of expert neuropsychologists offers a seamless
          online platform where you can receive comprehensive cognitive care,
          including diagnosis, treatment, and future follow-up. Our commitment
          to your long-term cognitive health and well-being is unwavering, and
          we are honored to be a part of your journey towards optimal brain
          function.
        </Typography>

        <Box maxWidth="md" margin="auto">
          <Typography
            style={{ color: "#ffffff", fontWeight: 500 }}
            pt={{
              md: 6,
              sm: 6,
              xs: 4,
            }}
            align="center"
            justifyContent="center"
            variant={large ? "h4" : medium ? "h4" : small ? "h5" : "h5"}
            gutterBottom
            sx={{
              backgroundImage: `linear-gradient(89.97deg, #ae67fa 1.84%, #f49867 102.67%)`,
              WebkitBackgroundClip: "text",
              WebkitTextFillColor: "transparent",
            }}
          >
            Clinical Services
          </Typography>

          <Typography
            variant="h6"
            sx={{
              color: "#f5f5f5",
              pt: 6,
              pb: 4,
            }}
          >
            <BsAward /> - How It Works:
          </Typography>
          <VerticalLinearStepper />
        </Box>

        <Typography
          style={{ color: "#ffffff", fontFamily: "Manrope" }}
          pt={{
            md: 6,
            sm: 6,
            xs: 4,
          }}
          align="center"
          justifyContent="center"
          variant={large ? "h4" : medium ? "h4" : small ? "h5" : "h5"}
          gutterBottom
          sx={{
            backgroundImage: `linear-gradient(89.97deg, #ae67fa 1.84%, #f49867 102.67%)`,
            WebkitBackgroundClip: "text",
            WebkitTextFillColor: "transparent",
          }}
        >
          Advanced Research and Development
        </Typography>

        <Typography
          maxWidth="md"
          text-align="justify"
          style={{
            color: "#8c8c8c",
            pt: 6,
            pt: 6,
            fontWeight: 500,
            fontSize: "18px",
          }}
          margin="auto"
          variant="body1"
        >
          As we advance into the digital age, we are committed to pushing the
          boundaries of cognitive rehabilitation research to provide you with
          the best possible treatment outcomes. To achieve this, we are
          constantly exploring new avenues and technologies to enhance our
          ability to track, monitor, and provide real-time feedback on your
          cognitive symptoms. Together, we are transforming the future of
          cognitive care.
        </Typography>
        <Typography
          maxWidth="md"
          textAlign="center"
          style={{ color: "#FF8A71" }}
          variant="h5"
          margin="auto"
          gutterBottom
          sx={{
            pt: 6,
            pb: 6,
          }}
        >
          <span>
            <RiDoubleQuotesL />{" "}
            <i>Take care of your brain, your brain will take care of you. </i>
            <RiDoubleQuotesR />
          </span>
        </Typography>
      </Container>
    </div>
  );
};

const steps = [
  {
    label: "Cognitive Evaluation",
    description:
      "Our  neuropsychologist will first give you a cognitive exam to evaluate your brain functioning.   This examination provides valuable information about your cognitive abilities, including memory, attention, problem-solving, multi-tasking, mental efficiency, and more. It helps us gain insights into the state of your cognitive health and detect any potential neurocognitive conditions.  The cognitive exam typically takes around 2-3 hours.  Your neuropsychologist will then meet with you to discuss the test results and provide treatment recommendations.",

    displayColor: "#bae0ff",
  },
  {
    label: "Cognitive Rehabilitation ",
    description:
      "After the cognitive exam, we will provide cognitive treatment. Whether you are dealing with memory loss, attention difficulties, or other cognitive issues, we work closely with you to implement effective strategies and interventions. Your neuropsychologist will closely track and evaluate your cognitive symptoms and treatment outcomes to ensure optimal care. Your doctor will frequently communicate with you about your progress and work collaboratively with you to achieve your cognitive health goals.  Treatment typically involves weekly 50-minute sessions over 6-10 weeks.",

    displayColor: "#ffd8bf",
  },

  {
    label: "Future Follow-up",
    description:
      "We prioritize your long-term cognitive health by conducting a follow-up with you three months after treatment to track your progress and ensure continued improvement of your brain function in activities that are important to you. ",

    displayColor: "#ffccc7",
  },
];

const VerticalLinearStepper = () => {
  const [active, setActiveStep] = React.useState(0);

  return (
    <Box ml={{ md: 6, sm: 3 }} sx={{ maxWidth: 600 }}>
      <Stepper orientation="vertical">
        {steps.map((step) => (
          <Step active={true} key={step.label}>
            <StepLabel>
              <Typography sx={{ color: "#f0f0f0", fontWeight: 500 }}>
                {step.label}
              </Typography>
            </StepLabel>
            <StepContent>
              <Typography
                sx={{
                  color: (theme) => theme.palette.grey[500],
                  fontWeight: 500,
                  fontSize: "17px",
                }}
              >
                {step.description}
              </Typography>

              <Box sx={{ mb: 2 }}>
                <div></div>
              </Box>
            </StepContent>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
};

export default AboutUs;
