import React from "react";

const jordanURL =
  "https://cdn-blob-momentous-health.azureedge.net/employee-image/jordan.png";

const jordan_hoffmeister = {
  slug: "jordan-hoffmeister",
  profile: {
    name: "Jordan Hoffmeister, PhD",
    surname: "Ph.D",
    image: jordanURL,
    position: "Researcher",
    motto:
      "Illuminating the secrets of the brain and helping individuals reach their full cognitive potential is not just a profession, it's our driving force.",
  },
  work_experice: {
    "Current Position": [
      {
        company: "Momentous Health, Inc.",
        position: "Researcher",
      },
    ],
  },
  education_and_training: [
    {
      position: "Post-doctoral Fellowship in Clinical Neuropsychology",
      location: "University of Oklahoma Health Sciences Center",
    },
    {
      position: "Pre-doctoral Internship in Neuropsychology",
      location: "University of Oklahoma Health Sciences Center",
    },
    {
      position: "PhD, Clinical Psychology, Specialty in Neuropsychology",
      location: "University of Tulsa",
    },
    {
      position: "BA, Psychology",
      location: "Oklahoma Christian University",
    },
  ],
};

export default jordan_hoffmeister;
