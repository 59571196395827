const tristaURL =
  "https://cdn-blob-momentous-health.azureedge.net/employee-image/trista.jpg";

const trista_protzeller = {
  slug: "trista-protzeller",
  profile: {
    name: "Trista Protzeller",
    surname: "",
    image: tristaURL,
    position: "Senior Psychometrist",
  },
  work_experice: {
    "Current Position": [
      {
        company: "Momentous Health, Inc.",
        position: "Senior Psychometrist",
      },
    ],
    "Past Positions": [
      {
        company:
          "General Internal Medicine at Harborview Medical Center/University of Washington",
        position: "Psychometrist Supervisor",
      },
      {
        company: "University of Washington",
        position: "Psychometrist",
      },
      {
        company: "The Rehabilitation Institute",
        position: "Lead Psychometrist",
      },
      {
        company: "University of Utah, Center for Alzheimer’s Care",
        position: "Lead Psychometrist",
      },
      {
        company: "Evergreen Medical Center",
        position: "Lead Psychometrist",
      },
      {
        company: "US Air Force, Travis Air Force Base",
        position: "Lead Psychometrist",
      },
    ],
  },
  education_and_training: [
    {
      position: "BS, Health and Human Services",
      location: "Western Governors University",
    },
  ],

  military_service: "US Air Force: Staff Sergeant E5",
};

export default trista_protzeller;
