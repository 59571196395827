import { Typography } from "@mui/material";
import ReviewCard from "./reviewCards";
import Grid from "@mui/material/Grid";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { GoQuote } from "react-icons/go";
import { RiDoubleQuotesL } from "react-icons/ri";
import "./review.css";

const Review = () => {
  const theme = useTheme();
  const isLg = useMediaQuery(theme.breakpoints.down("lg"));

  return (
    <div className="section__padding">
      <div className="title">
        <div className="gradient__text">
          <RiDoubleQuotesL />
          Reviews
        </div>
      </div>
      <Grid
        container
        spacing={1}
        alignItems="flex-end"
        sx={{ justifyContent: "space-evenly" }}
      >
        <Grid item xs={12} sm={6} md={6} lg={4}>
          <ReviewCard
            content="
                The online cognitive evaluation at Momentous Health saved me
                time without compromising on quality. My neuropsychologist
                provided precise insights into my cognitive health, and the
                tailored recommendations were spot-on. A truly time-efficient
                and personalized approach to cognitive assessment.
              "
            author="John S."
            rating={5} // Adjust the rating value as needed (from 1 to 5)
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={4}>
          <ReviewCard
            content="
            I'm thrilled with Momentous Health's all-in-one approach to care. Having the evaluation, treatment, and follow-up all in one place has made a world of difference. The follow-up care, in particular, is incredibly important to me. It's made managing my memory problems so much more convenient and efficient. I absolutely love their comprehensive care."
            rating={4.7} // Adjust the rating value as needed (from 1 to 5)
            author="Alex W."
          />
        </Grid>

        {isLg ? null : (
          <Grid item xs={12} sm={6} md={6} lg={4}>
            <ReviewCard
              content="My doctor took the time to thoroughly understand my attention and procrastination issues and provided strategies that worked for my specific needs.  The cognitive rehabilitation led to a remarkable improvement in my work performance.  I'm truly grateful for this outstanding experience that transformed my life."
              rating={4.4} // Adjust the rating value as needed (from 1 to 5)
              author="Erica G."
            />
          </Grid>
        )}
      </Grid>
      <div className="privacy">
        * To protect the privacy and confidentiality of our patients, we have
        changed the initials and locations of the reviews.
      </div>
    </div>
  );
};

export default Review;
