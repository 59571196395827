import * as React from "react";

const SelfCareSVG = (props) => (
  <svg
    width={64}
    height={64}
    viewBox="0 0 64 64"
    fill="none"
    xmlns="https://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M18.6666 43.3332H16C15.1159 43.3332 14.2681 42.982 13.643 42.3569C13.0178 41.7317 12.6666 40.8839 12.6666 39.9998V34.6665C12.6644 34.2974 12.5614 33.9358 12.3687 33.621C12.176 33.3061 11.901 33.0498 11.5733 32.8798L8.26665 31.2265L12.32 25.1198C12.32 25.1198 12.32 24.9865 12.32 24.9332L12.48 24.5598V24.1865C12.48 24.1865 12.48 24.0532 12.48 23.9998V21.3332C12.4833 20.1802 12.716 19.0394 13.1644 17.9772C13.6128 16.915 14.2681 15.9526 15.092 15.1461C15.9159 14.3396 16.892 13.705 17.9635 13.2793C19.0351 12.8536 20.1805 12.6453 21.3333 12.6665C21.8637 12.6665 22.3725 12.4558 22.7475 12.0807C23.1226 11.7056 23.3333 11.1969 23.3333 10.6665C23.3333 10.1361 23.1226 9.62736 22.7475 9.25229C22.3725 8.87722 21.8637 8.6665 21.3333 8.6665C17.9739 8.6665 14.7521 10.001 12.3766 12.3765C10.0012 14.7519 8.66665 17.9738 8.66665 21.3332V23.3865L3.67998 30.8798C3.51924 31.115 3.41205 31.3825 3.36597 31.6635C3.3199 31.9446 3.33606 32.2324 3.41331 32.5065C3.48068 32.7837 3.60898 33.0424 3.78888 33.2639C3.96878 33.4853 4.19576 33.6638 4.45331 33.7865L8.66665 35.8932V39.9998C8.66665 41.9448 9.43926 43.81 10.8145 45.1853C12.1898 46.5606 14.0551 47.3332 16 47.3332H16.6666V53.3332C16.6666 53.8636 16.8774 54.3723 17.2524 54.7474C17.6275 55.1225 18.1362 55.3332 18.6666 55.3332C19.1971 55.3332 19.7058 55.1225 20.0809 54.7474C20.4559 54.3723 20.6666 53.8636 20.6666 53.3332V45.3332C20.6597 44.8049 20.4468 44.3002 20.0732 43.9266C19.6996 43.553 19.1949 43.3401 18.6666 43.3332Z"
      fill="#ffa39e"
    />
    <path
      d="M45.3335 8.6665H34.6668C31.3074 8.6665 28.0856 10.001 25.7101 12.3765C23.3347 14.7519 22.0001 17.9738 22.0001 21.3332V23.3865L17.0135 30.8798C16.8527 31.115 16.7455 31.3825 16.6995 31.6635C16.6534 31.9446 16.6696 32.2324 16.7468 32.5065C16.8142 32.7837 16.9425 33.0424 17.1224 33.2639C17.3023 33.4853 17.5293 33.6638 17.7868 33.7865L22.0001 35.8932V39.9998C22.0001 41.9448 22.7728 43.81 24.148 45.1853C25.5233 46.5606 27.3886 47.3332 29.3335 47.3332H30.0001V53.3332C30.0001 53.8636 30.2109 54.3723 30.5859 54.7474C30.961 55.1225 31.4697 55.3332 32.0001 55.3332C32.5306 55.3332 33.0393 55.1225 33.4144 54.7474C33.7894 54.3723 34.0001 53.8636 34.0001 53.3332V45.3332C33.9932 44.8049 33.7803 44.3002 33.4067 43.9266C33.0331 43.553 32.5284 43.3401 32.0001 43.3332H29.3335C28.4494 43.3332 27.6016 42.982 26.9765 42.3569C26.3513 41.7317 26.0001 40.8839 26.0001 39.9998V34.6665C25.9979 34.2974 25.8949 33.9358 25.7022 33.621C25.5095 33.3061 25.2345 33.0498 24.9068 32.8798L21.6001 31.2265L25.6535 25.1198C25.6535 25.1198 25.6535 24.9865 25.6535 24.9332L25.8135 24.5598V24.1865C25.8135 24.1865 25.8135 24.0532 25.8135 23.9998V21.3332C25.8168 20.1802 26.0495 19.0394 26.4979 17.9772C26.9463 16.915 27.6016 15.9526 28.4255 15.1461C29.2494 14.3396 30.2255 13.705 31.297 13.2793C32.3685 12.8536 33.514 12.6453 34.6668 12.6665H45.3335C47.6299 12.6735 49.8302 13.5889 51.454 15.2127C53.0778 16.8365 53.9931 19.0368 54.0001 21.3332V29.8132C54.0072 32.584 53.1904 35.2944 51.6535 37.5998L48.9868 41.5465C48.7573 41.8746 48.6361 42.2662 48.6401 42.6665V53.3332C48.6401 53.8636 48.8509 54.3723 49.2259 54.7474C49.601 55.1225 50.1097 55.3332 50.6401 55.3332C51.1706 55.3332 51.6793 55.1225 52.0544 54.7474C52.4294 54.3723 52.6401 53.8636 52.6401 53.3332V43.2798L54.9601 39.8132C56.9279 36.8495 57.9762 33.3706 57.9735 29.8132V21.3332C57.9735 17.9784 56.6426 14.7606 54.2729 12.3859C51.9032 10.0112 48.6883 8.67357 45.3335 8.6665V8.6665Z"
      fill="#ff7875"
    />
    <path
      d="M40.3998 22.6931L39.9999 23.1998L39.4665 22.6665C38.699 21.8719 37.6592 21.3973 36.5561 21.3379C35.4529 21.2786 34.3682 21.6389 33.5198 22.3465C33.0988 22.7311 32.7597 23.1968 32.5228 23.7156C32.2858 24.2344 32.156 24.7957 32.1411 25.3658C32.1262 25.9359 32.2264 26.5032 32.4358 27.0337C32.6452 27.5642 32.9595 28.0469 33.3599 28.4531L39.2532 34.3465C39.3501 34.4465 39.4662 34.526 39.5944 34.5803C39.7227 34.6346 39.8606 34.6625 39.9999 34.6625C40.1391 34.6625 40.277 34.6346 40.4053 34.5803C40.5335 34.526 40.6496 34.4465 40.7465 34.3465L46.6665 28.4531C47.0687 28.0471 47.384 27.5634 47.5933 27.0316C47.8026 26.4998 47.9015 25.931 47.8841 25.3597C47.8666 24.7885 47.7331 24.2268 47.4917 23.7088C47.2503 23.1907 46.906 22.7272 46.4799 22.3465C45.623 21.5962 44.506 21.2122 43.369 21.2771C42.232 21.3419 41.1658 21.8504 40.3998 22.6931V22.6931Z"
      fill="#ff7875"
    />
  </svg>
);
export default SelfCareSVG;
