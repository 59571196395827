import React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { Link } from "react-router-dom";
import { Title, Navbar } from "../components";
import { useTheme } from "@mui/material/styles";
import { useMediaQuery } from "@mui/material";

const RayRenURL =
  "https://cdn-blob-momentous-health.azureedge.net/mh-web-image/Ray-R.jpg";

const lilylauURL =
  "https://cdn-blob-momentous-health.azureedge.net/mh-web-image/Lily-Lau.jpeg";

const manURL =
  "https://cdn-blob-momentous-health.azureedge.net/mh-web-image/profile-holder-man.jpg";

const girlURL =
  "https://cdn-blob-momentous-health.azureedge.net/mh-web-image/profile-holder-girl.jpg";

// TODO: temperarily disable the Tech employees
const cards = [
  // {
  //   name: "Rui Ren, PhD",
  //   role: "Chief Executive Officer",
  //   image: RayRenURL,
  //   link: "/team/rui-ren",
  // },
  {
    name: "Lily Lau, PhD",
    role: "Chief Clinical Officer",
    image:
      "https://cdn-blob-momentous-health.azureedge.net/employee-image/lily.png",
    link: "/team/lily-lau",
  },
  {
    name: "Bradley Reynolds, PhD",
    role: "Lead Neuropsychologist",
    image:
      "https://cdn-blob-momentous-health.azureedge.net/employee-image/brad.png",
    link: "/team/bradley-reynolds",
  },
  {
    name: "Jerry Chen, PhD",
    role: "Senior Neuropsychologist",
    image:
      "https://cdn-blob-momentous-health.azureedge.net/employee-image/JerryChen.png",
    link: "/team/jerry-chen",
  },
  {
    name: "Pelin Strebler, PhD, ABPP-CN",
    role: "Senior Neuropsychologist",
    image:
      "https://momentousblob.blob.core.windows.net/employee-image/pelin.png",
    link: "/team/pelin-strebler",
  },
  {
    name: "Morgan Glusman, PhD",
    role: "Neuropsychologist",
    image:
      "https://cdn-blob-momentous-health.azureedge.net/employee-image/morgan.JPG",
    link: "/team/morgan-glusman",
  },
  {
    name: "Zahra Sattaur, PsyD",
    role: "Neuropsychologist",
    image:
      "https://cdn-blob-momentous-health.azureedge.net/employee-image/zahra.jpg",
    link: "/team/zahra-sattaur",
  },
  {
    name: "Jordan Hoffmeister, PhD",
    role: "Researcher",
    image:
      "https://cdn-blob-momentous-health.azureedge.net/employee-image/jordan.png",
    link: "/team/jordan-hoffmeister",
  },
  // {
  //   name: "Adolfo Luna",
  //   role: "Senior Software Engineer",
  //   image:
  //     "https://cdn-blob-momentous-health.azureedge.net/employee-image/adolfo.JPG",
  //   link: "/team/adolfo-luna",
  // },
  // {
  //   name: "Sebastian Ren",
  //   role: "Software Engineer",
  //   image:
  //     "https://cdn-blob-momentous-health.azureedge.net/employee-image/sebastian.JPG",
  //   link: "/team/sebastian-ren",
  // },
  {
    name: "Trista Protzeller",
    role: "Senior Psychometrist",
    image:
      "https://cdn-blob-momentous-health.azureedge.net/employee-image/trista.jpg",
    link: "/team/trista-protzeller",
  },

  {
    name: "Ishawn Francis",
    role: "Clinic Growth Representative",
    image:
      "https://cdn-blob-momentous-health.azureedge.net/employee-image/Ishawn.jpg",
    link: "/team/ishawn",
  },
];

export default function TeamPage() {
  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  const theme = useTheme();
  const large = useMediaQuery(theme.breakpoints.up("lg"));
  const medium = useMediaQuery(theme.breakpoints.up("md"));
  const small = useMediaQuery(theme.breakpoints.up("sm"));

  return (
    <div>
      <Title title={"Our Teams"} />
      <Navbar />
      <main>
        <Box
          sx={{
            pt: 4,
            pb: 4,
          }}
        >
          <Container maxWidth="md" sx={{ textAlign: "center" }}>
            <Typography
              style={{ color: "#ffffff" }}
              fontWeight={500}
              pt={{
                md: 6,
                sm: 3,
              }}
              align="center"
              justifyContent="center"
              variant={large ? "h2" : medium ? "h2" : small ? "h3" : "h4"}
              gutterBottom
              sx={{
                backgroundImage: `linear-gradient(89.97deg, #ae67fa 1.84%, #f49867 102.67%)`,
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
              }}
            >
              Our Team
            </Typography>
            <Typography
              variant="h5"
              align="center"
              paragraph
              sx={{ color: "rgb(255, 138, 113)", pt: 8, lineHeight: 1.5 }}
            >
              Illuminating the secrets of the brain and helping individuals
              reach their full cognitive potential is not just a profession,
              it's our driving force.
            </Typography>
          </Container>
        </Box>

        <Container sx={{ py: 6, pb: 8 }} maxWidth="lg">
          <Grid container spacing={2}>
            {cards.map((card) => (
              <Grid
                item
                key={card.name}
                xs={12}
                sm={6}
                md={4}
                lg={3}
                sx={{
                  textAlign: "center",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Link to={card.link} onClick={scrollToTop}>
                  <Card
                    justifyContent="center"
                    alignItems="center"
                    sx={{
                      width: "255px",
                      display: "flex",
                      flexDirection: "column",
                      margin: "auto",
                    }}
                  >
                    <CardMedia
                      component="img"
                      height="200px"
                      image={card.image}
                      sx={{
                        objectFit: "contain",
                        filter: "grayscale(100%)",
                        transition: "filter 0.3s ease",
                        "&:hover": {
                          filter: "none",
                        },
                      }}
                    />

                    <CardContent sx={{ flexGrow: 1 }}>
                      <Typography
                        gutterBottom
                        variant="body1"
                        sx={{
                          textAlign: "center",
                          fontWeight: "bold",
                        }}
                      >
                        {card.name}
                      </Typography>
                      <Typography
                        gutterBottom
                        variant="body2"
                        sx={{
                          textAlign: "center",
                          fontWeight: 500,
                        }}
                      >
                        {card.role}
                      </Typography>
                    </CardContent>
                  </Card>
                </Link>
              </Grid>
            ))}
          </Grid>
        </Container>
      </main>

      <Container
        maxwidth="md"
        component="main"
        sx={{ pt: 6, pb: 4 }}
      ></Container>
    </div>
  );
}
