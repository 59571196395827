const ishawnURL =
  "https://cdn-blob-momentous-health.azureedge.net/employee-image/Ishawn.jpg";

const Ishawn = {
  slug: "ishawn",
  profile: {
    name: "Ishawn Francis",
    surname: "",
    image: ishawnURL,
    position: "Clinic Growth Representative",
  },
  work_experice: {
    "Current Position": [
      {
        company: "Momentous Health, Inc.",
        position: "Clinic Growth Representative",
      },
    ],
    "Past Positions": [
      {
        company: "ChenMed-Dedicated Senior Medical Center-Charlotte, NC",
        position: "Healthcare Sales Representative",
      },
      {
        company: "Cigna-Accredo Specialty Pharmacy",
        position: "Quality Assurance Team Lead",
      },
      {
        company: "Wake Forest University-Winston-Salem, NC",
        position: "Clinical Research Specialist",
      },
    ],
  },
  education_and_training: [
    {
      position: "MS, Health and Exercise Science",
      location: "Wake Forest University",
    },
    {
      position: "BS, Biology",
      location: "Claflin University Orangeburg",
    },
  ],
};

export default Ishawn;
