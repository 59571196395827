import React, { useState } from "react";
import { AiOutlineMinus, AiOutlinePlus } from "react-icons/ai";
import "./question.css";

const SingleQuestion = ({ question }) => {
  let [showMore, setShowMore] = useState(false);

  const openQuestion = () => {
    setShowMore(!showMore);
  };

  return (
    <div className="SingleQuestion">
      <header
        className="SingleQuestion-header"
        onClick={() => setShowMore(!showMore)}
      >
        <div className="SingleQuestion-title">{question.title}</div>
        <button
          type="button"
          className="SingleQuestion-button"
          onClick={() => setShowMore(!showMore)}
        >
          {showMore && <AiOutlineMinus />}
          {!showMore && <AiOutlinePlus />}
        </button>
      </header>

      {showMore && <p className="info">{question.info}</p>}
    </div>
  );
};

export default SingleQuestion;
