import React from "react";
import Toolbar from "@mui/material/Toolbar";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import { BsSearch } from "react-icons/bs";
import Typography from "@mui/material/Typography";
import { Link } from "react-router-dom";
import { CssBaseline } from "@mui/material";

const Header = (props) => {
  const { sections, title } = props;

  return (
    <React.Fragment>
      <Toolbar sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Link to="/">Home</Link>
        <Typography
          component="h2"
          variant="h5"
          color="inherit"
          align="center"
          noWrap
          sx={{ flex: 1 }}
        >
          <Link to="/">Momentous Health</Link>
        </Typography>
        <IconButton>
          <BsSearch />
        </IconButton>

        <Button
          variant="outlined"
          size="small"
          style={{ textTransform: "none" }}
        >
          Subscribe
        </Button>
      </Toolbar>
      <CssBaseline />
    </React.Fragment>
  );
};

export default Header;
