import { Helmet } from "react-helmet";

const Title = ({ title, description, keywords }) => {
  return (
    <Helmet>
      {/*!-- Google tag (gtag.js) --*/}
      <script
        async
        src="https://www.googletagmanager.com/gtag/js?id=G-WKZ6ZK8NF7"
      ></script>
      <script>
        {`window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', 'G-WKZ6ZK8NF7');
        `}
      </script>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="keywords" content={keywords} />
    </Helmet>
  );
};

Title.defaultProps = {
  title: "Welcome to Momentous Health | The Dementia Clinic",
  description: "Neuropsychology evaluation and treatment",
  keywords: "Cognitive test and treatment, e.g. dementia, alzheimer's disease",
};

export default Title;
