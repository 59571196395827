import React from "react";
import "./notion.css";
import BannerImage from "./bannerImage";

const access_contents = [
  { title: "NeuroPsychology Evaluation", text: "Learn more ..." },
  { title: "NeuroPsychology Rehabilitation", text: "Join us ..." },
  { title: "NeuroPsychology Research", text: "Find our research ..." },
];

const Notion = () => {
  return (
    <div className="section__padding notion">
      <div className="goal">
        <div className="gradient__text slogan">
          Cognitive Health Services <br></br> at Your Convenience
        </div>
        <p>
          Our mission is to offer accessible online services so that you can
          better understand, manage, and optimize your cognitive health.
        </p>
      </div>
      <div className="bannerImage">
        <BannerImage />
      </div>
    </div>
  );
};

export default Notion;
