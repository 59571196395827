import React from "react";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";

const Sidebar = (props) => {
  const { archives, description, social, title } = props;
  return (
    <Grid item xs={12} md={4}>
      <Paper elevation={0} sx={{ p: 2, bgcolor: "grep.200" }}>
        <Typography variant="h6" gutterBottom>
          {title}
        </Typography>
        <Typography variant="h6" gutterBottom sx={{ mt: 3 }}>
          Archives
        </Typography>
        {archives.map((archive) => (
          <Link
            display="block"
            variant="body1"
            href={archive.url}
            key={archive.title}
          >
            {archive.title}
          </Link>
        ))}

        <Typography variant="h6" gutterBottom sx={{ mt: 3 }}>
          Social
        </Typography>
        {social.map((network) => (
          <Link
            display="block"
            variant="body1"
            href="#"
            key={network.name}
            sx={{ mb: 0.5 }}
          >
            <Stack direction="row" spacing={1} alignItem="center">
              <a href={network.url} target="_blank">
                <network.icon /> {""}
                <span>{network.name}</span>
              </a>
            </Stack>
          </Link>
        ))}
      </Paper>
    </Grid>
  );
};

export default Sidebar;
