import React, { useState, useEffect } from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { FaRegPaperPlane } from "react-icons/fa";
import axios from "axios";

const QuestionaireForms = () => {
  const [FirstName, setFirstName] = useState("");
  const [LastName, setLastName] = useState("");
  const [Email, setEmail] = useState("");
  const [Message, setMessage] = useState("");

  // validate the email format from frontend
  const validateEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (regex.test(email)) {
      return true;
    }
    return false;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    // We need to validate email
    const ValidEmail = validateEmail(Email);

    // validate the Message format
    const ValidMessage = Message.trim() !== "";

    // only send request to backend when email is valid and message is not empty
    if (ValidEmail && ValidMessage) {
      try {
        const response = await axios.post("/api/v1/enquiry/inquiry/", {
          first_name: FirstName,
          last_name: LastName,
          email: Email,
          message: Message,
        });
        alert(response.data);
        setFirstName("");
        setLastName("");
        setEmail("");
        setMessage("");
      } catch (error) {
        if (error.response) {
          alert(error.response.data);
        } else if (error.request) {
          console.log(error.request);
        } else {
          console.log("Error", error.message);
        }
      }
    } else {
      alert("Please input the correct value!");
    }
  };

  return (
    <Container maxWidth="sm" pt={6} px={6} py={6}>
      <Typography variant="h5" color="grey.300" pt={6} pb={3} gutterBottom>
        <span>
          <FaRegPaperPlane color="pink" /> {"   "}
          Contact Us
        </span>
        <Typography variant="body1" pt={4}>
          Send us your messages and we will get back to you shortly.
        </Typography>
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="firstName"
            name="firstName"
            label="First Name"
            size="small"
            fullWidth
            autoComplete="given-name"
            variant="filled"
            sx={{ backgroundColor: "#f5f5f5" }}
            value={FirstName}
            onChange={(e) => setFirstName(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="lastName"
            name="lastName"
            label="Last Name"
            size="small"
            fullWidth
            autoComplete="family-name"
            variant="filled"
            sx={{ backgroundColor: "#f5f5f5" }}
            value={LastName}
            onChange={(e) => setLastName(e.target.value)}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            required
            id="email"
            type="email"
            name="email"
            label="Email"
            size="small"
            fullWidth
            autoComplete="email"
            variant="filled"
            sx={{ backgroundColor: "#f5f5f5" }}
            value={Email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            required
            multiline
            id="message"
            name="message"
            label="Message"
            rows={8}
            fullWidth
            variant="filled"
            sx={{ backgroundColor: "#f5f5f5" }}
            value={Message}
            onChange={(e) => setMessage(e.target.value)}
          />
        </Grid>
      </Grid>
      <Grid container sx={{ justifyContent: "flex-end" }} mt={2} pt={2} pb={2}>
        <Button
          variant="outlined"
          sx={{ textTransform: "none", backgroundColor: "#f0f0f0" }}
          onClick={handleSubmit}
        >
          <Typography>Submit</Typography>
        </Button>
      </Grid>
    </Container>
  );
};

export default QuestionaireForms;
