import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Markdown from "./Markdown";
import "./markdown.css";

const Main = (props) => {
  const { title, blog_name } = props;
  const [post, setPost] = useState("");

  useEffect(() => {
    import(`./${blog_name}.md`)
      .then((res) => {
        fetch(res.default)
          .then((res) => res.text())
          .then((res) => setPost(res));
      })
      .catch((err) => console.log(err));
  });

  return (
    <Grid
      item
      xs={12}
      md={8}
      sx={{
        "&.markdown": {
          py: 3,
        },
      }}
    >
      <i>
        <Typography variant="body1" gutterBottom>
          {title}
        </Typography>
      </i>

      <Divider />
      <Markdown key={title}>{post}</Markdown>
    </Grid>
  );
};

export default Main;
