import React from "react";

const lilylauURL =
  "https://cdn-blob-momentous-health.azureedge.net/employee-image/lily.png";

const lily_lau = {
  slug: "lily-lau",
  profile: {
    name: "Lily Lau, Ph.D.",
    surname: "Ph.D",
    image: lilylauURL,
    position: "Chief Clinical Officer",
    motto:
      "Illuminating the secrets of the brain and helping individuals reach their full cognitive potential is not just a profession, it's our driving force.",
  },
  work_experice: {
    "Current Positions": [
      {
        company: "Momentous Health, Inc.",
        position: "Chief Clinical Officer",
      },
      {
        company: "American Congress of Rehabilitation Medicine",
        position: "Member, Digital and Telehealth Task Force",
      },
    ],
    "Past Position": [
      {
        company:
          "Program in Rehabilitation Neuroscience at VA Northern California Health Care System",
        position: "Clinical Research Scientist",
      },
    ],
  },

  education_and_training: [
    {
      position: "Post-doctoral Fellowship in Clinical Neuropsychology",
      location:
        "VA Northern California Health Care System and UC Davis Medical Center, Department of Neurology",
    },
    {
      position: "Pre-doctoral Internship in Neuropsychology",
      location: "VA Northern California Health Care System",
    },
    {
      position: "PhD, Clinical Psychology, Specialty in Neuropsychology",
      location: "University of Tulsa",
    },
    {
      position: "BS with Highest Honors, Psychology",
      location: "UC San Diego",
    },
  ],
};

export default lily_lau;
