export const data = [
  {
    id: 1,
    title: "Why should I seek my cognitive care at Momentous Health?",
    info: "Our comprehensive approach to cognitive care  is unparalleled. We streamline your health needs by providing cognitive exams, treatment, and follow-up care all in one place. We take great care to understand the nature, severity, and underlying cause of your cognitive symptoms, and tailor evidence-based treatment to your unique cognitive needs. Our goal is to help you improve your brain functioning so that you can excel in the activities that matter most to you. That is why cognitive treatment and long-term follow-up are a critical part of our care, which sets us apart from other clinics. Additionally, we constantly strive to integrate the latest advancements in technology and treatment methods into our care to ensure the best possible outcomes for our patients. By choosing Momentous Health, you are choosing a clinic that prioritizes your long-term cognitive health and well-being.",
  },
  {
    id: 2,
    title: "Why does the neuropsychological test take so long?",
    info: "The cognitive exam is a thorough process that typically requires 2-3 hours to complete. While it may feel lengthy, this duration is essential for our neuropsychologists to gather comprehensive data on how your is brain functioning. This information is crucial in determining the nature, severity, and cause of your cognitive issues, and it guides the development of an appropriate treatment plan tailored to your specific needs. We understand that it can be challenging to concentrate for long periods, which is why we provide opportunities for you to take breaks throughout the exam as needed.",
  },
  {
    id: 3,
    title: "How long will it take to schedule my first appointment?",
    info: "We typically will be able to schedule your first appointment within one week.",
  },
  {
    id: 4,
    title: "How long will it take to get my cognitive exam results?",
    info: "Your neuropsychologist typically will be able to give you your cognitive exam results within one week.",
  },
  {
    id: 5,
    title:
      "Can I get cognitive treatment at Momentous if I had already completed a cognitive exam elsewhere?",
    info: "Yes, we offer cognitive treatments for patients who had already completed a cognitive exam.  Please bring your cognitive exam results to your initial treatment appointment.",
  },
  {
    id: 6,
    title: "Do you offer in-person appointments?",
    info: "We prioritize making our services easily accessible and convenient for you by offering online appointments. This approach has proven especially popular and effective due to COVID-19 safety measures, and many patients have found it more convenient than in-person visits. However, if you prefer to have an in-person appointment, please contact us to inquire about the availability of this option in your area.",
  },
];
