import React from "react";
import "./article.css";
import { Link } from "react-router-dom";

const Article = ({ imageUrl, date, title, slug }) => {
  // define scroll to top function
  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  const subURL = `/Blog/${slug}`;

  return (
    <div className="BH__blog-container_article">
      <div className="BH__blog-container_article-image">
        <img src={imageUrl} alt="img" />
      </div>
      <div className="BH__blog-container_article-content">
        <p>{date}</p>
        <h3>{title}</h3>
        <h4>
          <Link to={subURL} onClick={scrollToTop}>
            Read the Full Article
          </Link>
        </h4>
      </div>
    </div>
  );
};

export default Article;
